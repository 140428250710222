import React from "react"
import AppBarNew from "../../components/Appbar"
import Footer from "../../components/Footer"
import "./Privacy.css"
import backdrop from "../../../images/Backdrop/Page5.png"
import Head from "../../components/Head"

function Privacy() {
  return (
    <>
      <Head pageTitle="Privacy Policy" />
      <AppBarNew />
      <div className="part1">
        <img className="img" src={backdrop} alt="" />
        <div className="imgtxt1">Legal</div>
        <div className="imgtxt2">Integrity & Privacy Policy</div>
      </div>
      <div className="part2 div-root">
        <h1 className="head1">Integrity & Privacy Policy</h1>
        <div className="head2">Highlights from the policy:</div>
        <p className="head2text">
          Racqy does NOT share your data with advertisers & the like for
          commercial purposes.
          <br />
          As a user, you have the right to have your personal data deleted from
          our system (however, some information may need to be saved in
          accordance with the Swedish Accounting Act).
          <br />
          You can see what personal data relating to you has been processed and
          how your personal data is used.
          <br />
          Information about the operating environment and how the data is
          protected.
          <br />
          Preapproved subcontractors/suppliers of Racqy AB.
        </p>

        <h1 className="head3">Privacy policy for users of Racqy</h1>
        <p className="head3pera2">
          As a platform provider, we work continuously to ensure that your
          personal privacy is protected when you use the services we provide.
          Your personal data is processed in accordance with applicable
          legislation (the GDPR). Therefore, we have implemented this privacy
          policy that determines how your personal data will be processed by us.
        </p>
        <p className="head3pera2">
          Racqy handles the buyer’s personal data in accordance with the Data
          Protection Regulation (GDPR) and never distributes it to parties who
          are not affiliated with Racqy. The only exception is if we explicitly
          request your approval at the time of purchase.
        </p>
        <p className="head3pera2">
          Racqy AB processes all information about our users, i.e., personal
          name, email and other information, with data support. This data is
          processed in order to manage the customer relationship between you and
          the facilities and partners affiliated with Racqy, as well as to
          provide important information about your purchased services (such as
          booking confirmation).{" "}
        </p>

        <h1 className="head4">
          Parties and liabilities for the processing of your personal data
        </h1>
        <p className="pera5">
          Racqy AB, 559306-9668, Birger Jarlsgatan 2, 114 34 Stockholm, is a
          platform provider of systems for bookings, membership databases,
          billing, etc. Racqy is a data processor of your personal data for
          third parties (the facilities where you make your booking). Racqy is
          also the data controller for processing your data in Racqy’s own
          databases. Users who have their own login information for our website
          are henceforth called Users.
        </p>
        <p className="pera5-new">
          Racqy is the data controller for processing the personal data that you
          share with us when:
        </p>

        <p className="list1">
          1.You create an account on any of the Racqy webplatform
        </p>
        <p className="list2">2.You use Racqy’s app</p>
        <p className="list3">3.You have a question and/or contact us</p>
        <p className="list4">4.You visit our website and accept cookies</p>

        <h1 className="head5">
          What personal data relating to you do we process?
        </h1>
        <p className="pera5">
          The personal data that is processed will vary depending on whether you
          are a private individual or a business. If you are a business, it will
          vary depending on what type of business you have. Business data may be
          personal data for a customer that is self-employed. When you create an
          account with us as a User, we collect data that are required,
          including: email address and first name and surname. Completing your
          information on your profile is optional. When a private individual
          creates an account or a seller uses our service, we collect their
          contact details and company information depending on the type of
          customer they are at Racqy.
        </p>

        <h1 className="head6">Handling personal data</h1>
        <p className="pera5">
          Racqy saves the User’s personal data, and other such data that is
          attributable to them, only to the extent that it is necessary for the
          User to use their account in so far as the User does not consent to
          additional services, such as customer surveys and newsletters. The
          user can always request that Racqy delete all data stored about them
          at Racqy, but then the User can no longer avail of the service.
        </p>
        <p className="pera5">
          Racqy certifies that when data is shared with third parties, it is
          done in accordance with prevailing legislation and Racqy is
          responsible for ensuring that all Racqy’s suppliers comply with EU
          requirements regarding the handling of personal data.
        </p>
        <p className="pera5">
          In this privacy policy, the User’s personal data refers to any
          information about the User through which the User is directly or
          indirectly identifiable, including, but not limited to, the User’s
          name, address, date of birth, IP address, account and email address,
          and other information through which the User can be identified. Racqy
          is, unless otherwise specified, the data processor in accordance with
          the GDPR for Racqy’s processing of personal data that occurs when
          making a booking. Racqy may avail of this data from the User’s account
          when the User creates an account, through the User’s acceptance of
          this privacy policy. Racqy is the data controller for its own database
          of Users.{" "}
        </p>
        <p className="pera5">
          The personal data is processed by Racqy for the overall purpose of
          being able to provide the data controller (the seller) with
          information when a booking is made and which determines the data to be
          processed and how the data shall be used. By registering a user
          account, the User agrees to the processing of their personal data for
          such purposes. Racqy saves personal data to the extent that it is
          necessary for the User to be able to book with a third party (the
          seller). The User has the right to find out which of their data is
          processed by Racqy by contacting Racqy via the contact details
          provided on the website.{" "}
        </p>
        <p className="pera5">
          The User may at any time request that Racqy ceases processing their
          personal data and thereby deletes such data belonging to the User and
          being stored by Racqy. However, such a request may mean that Racqy
          cannot continue to provide an account with Racqy. The User can
          terminate his/her user account at any time by deregistering as a user
          or by notifying Racqy in writing of such a request. After the user’s
          account has been terminated, the User’s personal data will be deleted
          after a reasonable period of time, providing no other legal
          requirements such as the Swedish Accounting Act prevent this.
        </p>
        <p className="pera5">
          Racqy does not have the right to pass on the User’s personal data to a
          third party unless this is directly necessary for the provision of the
          service. Consequently, Racqy does not share personal data for any
          other commercial purpose.
        </p>

        <h1 className="head7"> Data Controller and Data Protection Officer</h1>
        <p className="pera5">
          Racqy.se, which is operated by Racqy AB, Org. No. 556871-6129, is the
          data processor for any processing of personal data carried out by a
          third party (the seller) and which is described in this privacy
          policy, as well as the data controller for the database owned by
          Racqy.
        </p>
        <p className="pera5">
          Our postal address is Birger Jarlsgatan 2, 114 34 Stockholm. Email
          address: info@racqy.com .Phone: +46 (0)72-502 25 05
        </p>
        <p className="pera5">Our Data Protection Officer is: Elliot Bennet</p>

        <h1 className="head8">Forwarding of personal data</h1>
        <p className="pera5">
          Your personal data that we may send to third parties, and also save,
          include name, date of birth, address, email address, phone number.
          Personal identity numbers are only collected and shared when required
          by federations in each country. All the data that you submit to us
          through the app is encrypted before storage.
        </p>

        <h1 className="head9">Consent</h1>
        <p className="pera5">
          In order for Racqy to be able to process your personal data and at the
          time of booking submit your personal data to a third party, you, the
          User, must consent. Consent is voluntary for our users. You can
          withdraw your consent at any time by contacting us at Racqy. Consent
          is required for our users to book or otherwise utilize the services
          provided by Racqy.
        </p>

        <h1 className="head10">Information security</h1>
        <p className="pera5">Google är för närvarande värd för Racqy. </p>
        <p className="pera5">
          Google have years of experience in designing, building and operating
          large-scale data centres. Physical access to these data centres is
          strictly controlled by professional security personnel. Authorized
          personnel must go through multiple authentication steps to gain access
          to these data centres. The design and precise location of these data
          centres constitute classified information to which, for security
          reasons, only selected members of the Amazon staff have access
        </p>
        <p className="pera5">
          We never store any credit card information in our databases. All
          sensitive payment information is handled securely by our partner
          Adyen. All communication with our payment partners is encrypted in
          accordance with industry standards. Passwords are always saved one-way
          encrypted in our databases.
        </p>
        <p className="pera5-mt">Personal data breaches</p>
        <p className="pera5">
          Racqy is liable for processing your data as securely as possible by
          following the requirements set out in the GDPR
        </p>
        <p className="pera5">
          Racqy assumes its obligations in the event of a personal data breach.
          In the event of such a breach, Racqy will report the breach to the
          Data Inspection Board within 72 hours, and inform the affected parties
          as well as specify what we will do to remedy and prevent a breach in
          the future.{" "}
        </p>
      </div>
      <br />
      <Footer />
    </>
  )
}

export default Privacy
